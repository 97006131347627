@import '../../_config/config.scss';

.container {
	font-size: 17px;
	padding: 15px;
	@include desktop {
		height: 20px;
		display: flex;
	}
}

.text {
	overflow: hidden;
	text-overflow: ellipsis;
	white-space: nowrap;
	text-align: center;
}

.container:hover {
	cursor: pointer;
}

.id {
	@include desktop {
		width: 5%;
	}
	@include mobile {
		display: none;
	}
}

.fileName {
	@include desktop {
		width: 55%;
	}
	@include mobile {
		font-size: 20px;
		padding: 5px;
	}
}

.fileSize {
	@include desktop {
		width: 10%;
	}
	@include mobile {
		text-align: right;
		padding-right: 20px;
	}
}

.owner {
	@include desktop {
		width: 10%;
	}
	@include mobile {
		text-align: right;
		padding-right: 20px;
	}
}

.uploadTime {
	@include desktop {
		width: 20%;
	}
	@include mobile {
		text-align: right;
		padding-right: 20px;
	}
}
