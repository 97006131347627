@import '../_config/config.scss';

.container {
	@include mobile {
		display: none;
	}
	@include desktop {
		position: fixed;
		height: 100%;
		overflow: hidden;
		box-sizing: border-box;
		border-right: 1px solid black;
	}
}

.service {
	font-size: 1.2em;
	text-align: center;
	padding: 5px;
	margin: 5px auto;
	margin-top: 15px;
	color: #ab9b83;
	font-weight: 700;
}

.logout {
	width: 90%;
	height: 40px;
}

.text {
	font-size: 16px;
	font-weight: 700;
}

.box {
	position: absolute;
	width: 100%;
	bottom: 80px;
}
