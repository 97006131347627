@import '../../_config/config.scss';

.container {
	@include mobile {
		width: 100%;
		border-top: 1px solid black;
	}
	@include desktop {
		width: 90%;
		margin: 0 auto;
	}
}

.item {
	@include mobile {
		border-bottom: 1px solid black;
	}
	@include desktop {
		border-bottom: 1px solid black;
	}
}

.item.title {
	@include mobile {
		display: none;
	}
}

.item:not(.title):hover {
	background-color: #ddd;
}

.title {
	border-top: 1px solid black;
	background-color: #1976d2;
	color: white;
	font-weight: 600;
}

.box {
	@include desktop {

		border-right: 1px solid #999;
		border-left: 1px solid #999;
		border-bottom: 1px solid #999;
	}
	// @include mobile {
	// 	height: 100%;
	// }
	height: 70vh;
	position: relative;
	overflow-y: overlay;
}
