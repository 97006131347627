@import '../../_config/config.scss';

.progress {
	display: flex;
	margin: 10px 20px;
	padding: 10px;
	align-items: center;
}

.value {
	margin-right: 50px;
	width: 200px;
	letter-spacing: 1px;
}

.usage {
	width: 150px;
}

.progressbar {
	margin-right: 20px;
	margin-left: 10px;
	width: 100%;
}

.group {
	width: 88%;
	height: 50px;
	margin: 10px auto;
	display: flex;
	flex-direction: row-reverse;
	align-items: center;
}

.group > Button {
	margin: 10px;
	width: 100px;
}

.desc {
	@include mobile {
		display: none;
	}
	color: #555;
	margin-right: 20px;
}
