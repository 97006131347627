@import '../_config/config.scss';

.side {
	@include desktop {
		width: 250px;
	}
}

.container {
	display: flex;
}

.main {
	@include desktop {
		position: fixed;
		width: calc(100% - 250px);
		left: 250px;
		height: 100%;
	}
    @include mobile{
        width: 100%;
    }
}
